import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { FormControl, FormLabel, Stack } from '@mui/material';
import { styled } from '~/themes';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';

export interface TimeSelectorProps {
  id?: string;
  value?: Moment | null;
  legend?: string;
  error?: boolean;
  helperText?: string;
  onChange?: (value: Moment) => void;
  onBlur?: (v: Moment) => void;
}

const StyledTimePeriods = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'checked',
})<{ checked?: boolean }>(({ theme, checked }) => ({
  height: 30,
  width: 50,
  background: theme.bg.lightPurple,
  borderRadius: '5px',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 3,
  color: theme.color.violet600,
  cursor: 'pointer',
  zIndex: 1,
  ...(checked && {
    background: theme.bg.darkPurple,
    color: theme.color.white,
    zIndex: 2,
  }),
}));

export const TimeSelector: React.FC<TimeSelectorProps> = ({
  id,
  value,
  legend,
  error = false,
  helperText,
  onChange,
  onBlur,
}) => {
  const timePeriods = value ? value?.format?.('a') : '';

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <FormControl>
        <FormLabel>{legend}</FormLabel>
        <Stack direction='row'>
          <TimeField
            value={value || null}
            format='hh:mm'
            onChange={(v: Moment) => {
              onChange(v);
            }}
            slotProps={{
              textField: {
                id,
                error,
                helperText,
                ...(onBlur && {
                  onBlur: (e) => {
                    if (!value?.isValid()) {
                      const updateValue = moment();
                      const v = e.target.value;
                      const h = v.split(':')[0];
                      const m = v.split(':')[1];
                      if (h !== 'hh' && m === 'mm') {
                        updateValue.set('hours', parseInt(h, 10));
                        updateValue.set('minutes', 0);
                      }
                      onChange(updateValue);
                      onBlur(updateValue);
                    } else {
                      onBlur(value);
                    }
                  },
                }),
              },
            }}
          />
          <StyledTimePeriods
            checked={timePeriods === 'am'}
            sx={{ ml: 2, mr: -0.8 }}
            onClick={() => {
              if (timePeriods === 'am' || !value?.isValid?.()) return;
              onChange(moment(value).subtract(12, 'hours'));
            }}
          >
            am
          </StyledTimePeriods>
          <StyledTimePeriods
            checked={timePeriods === 'pm'}
            onClick={() => {
              if (timePeriods === 'pm' || !value?.isValid?.()) return;
              onChange(moment(value).add(12, 'hours'));
            }}
          >
            pm
          </StyledTimePeriods>
        </Stack>
      </FormControl>
    </LocalizationProvider>
  );
};
