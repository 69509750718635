import React, { useState, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getNewSort } from '~/utils/common';
import { updateLocationSearchParams } from '~/utils/formatter';
import themes, { styled } from '~/themes';
import { useGetTasks } from '~/pages/LiveTracking/apis';
import Button from '~/components/common/Button';
import {
  addToQueryString,
  cleanDeep,
  fromQueryString,
  omitFromQueryString,
  toQueryString,
} from '~/utils/queryString';
import { Stack, Typography } from '@mui/material';
import { useAuth } from '~/providers/AuthProvider';
import Tutorial from '~/components/common/Tutorial';
import { TutorialData } from '~/constants/tutorial';
import Tabs from '~/components/common/Tabs';
import { ITabValue } from '~/models/shared';
import { ITaskResponse } from '~/pages/LiveTracking/types';
import { useFirstLoadingDetection } from '~/hooks';
import {
  StyledFilterContainer,
  StyledPanelDetailContainer,
} from '~/themes/style';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { PageLayout } from '~/layout/PageLayout';
import { useKeyDow } from '~/hooks/useKeyDown';
import { TaskDetail } from '~/pages/LiveTracking/components/TaskDetail';
import { Pagination } from '~/components/common/Pagination';
import ExportIcon from '~/assets/images/icons/export.svg';
import ImportIcon from '~/assets/images/icons/import.svg';
import AddIcon from '~/assets/images/icons/add-white.svg';
import { useExportTasks } from '~/services/api/task';
import { FilterForm } from './FilterForm';
import { TaskTable } from './TaskTable';
import SetupYourTask from './SetupYourTask';
import { ImportTask } from './ImportTask';
import { DialogAddTask } from '../LiveTracking/components/AddTask';

export const StyledHeaderButtonContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  paddingBottom: theme.spacing(2),
  gap: theme.spacing(0.8),
  [theme.breakpoints.down('md')]: {
    paddingBottom: 0,
    marginTop: theme.spacing(1),
  },
}));
type ITaskListProps = {};

export const TaskList: React.FC<ITaskListProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { account, getTooltipOpen, updateUserTooltip } = useAuth();
  const { org, setIsOpenUpSellDialog, isStarterPlan } = usePaymentInfo();
  const [openCreateTaskDialog, setOpenCreateTaskDialog] = useState(false);
  const [openImportTaskDialog, setOpenImportTaskDialog] = useState(false);
  const [tabCounts, setTabCounts] = useState({
    ongoing: null,
    completed: null,
  });

  const itemPerPage: number = account?.items_per_page || 10;
  const { tab, ...extraLocationSearch } = fromQueryString(location.search);
  const {
    id,
    search,
    statuses,
    executor_ids,
    preferred_team_ids,
    page: p,
    sort,
    sort_by,
    reassigned_status,
    date_gte,
    length,
    timezone,
  } = extraLocationSearch || {};
  const tabValue = (tab || ITabValue.Ongoing) as ITabValue;
  const taskId = id as string;
  const page = (p || 1) as string;

  const {
    data,
    isFetching: isFetchingTask,
    isPreviousData,
  } = useGetTasks({
    params: {
      search,
      statuses: statuses || tabValue,
      executor_ids,
      preferred_team_ids,
      page,
      limit: itemPerPage,
      sort,
      sort_by,
      reassigned_status,
      date_gte,
      length,
      timezone,
    },
    refetchInterval: 7 * 1000,
    enabled: !!account,
    onSuccess: (resp: ITaskResponse) => {
      const newTabCounts =
        tabValue === ITabValue.Ongoing
          ? {
              ongoing: resp.total_items,
              completed: resp.done_list,
            }
          : {
              ongoing: resp.not_done_list,
              completed: resp.total_items,
            };
      setTabCounts(newTabCounts);
    },
  });

  const handleDownloadCSV = (csvString: string) => {
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Export All Tasks.csv');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const { mutate: exportAllTaskMutation, isLoading: isLoadingExportAllTask } =
    useExportTasks({
      onSuccess: (csvData) => {
        handleDownloadCSV(csvData?.csv);
      },
    });

  const isShowTaskListLoading = !(!isFetchingTask || !isPreviousData);
  const isFirstLoading = useFirstLoadingDetection([isFetchingTask]);

  const isFullEmpty =
    isFirstLoading ||
    (tabCounts.completed === 0 &&
      tabCounts.ongoing === 0 &&
      !Object.keys(extraLocationSearch).length);

  const { total_pages } = data || {};

  const tasks = useMemo(
    () => (data?.items.length ? data.items.filter((t) => !t.isDivider) : []),
    [data],
  );

  const onSubmit = useCallback(
    (values: any) => {
      cleanDeep(values, { excludeKeys: ['search'] });
      navigate({
        pathname: location.pathname,
        search: addToQueryString(omitFromQueryString(location.search, 'id'), {
          ...values,
          page: 1,
        }),
      });
    },
    [location],
  );

  const onClear = useCallback(() => {
    navigate({
      pathname: location.pathname,
      search: omitFromQueryString(location.search, ['search']),
    });
  }, [location]);

  const onClearAll = useCallback(() => {
    navigate({
      pathname: location.pathname,
      search: `?tab=${tabValue}`,
    });
  }, [location]);

  const handleSort = useCallback(
    (sortId: string, nextArrange: number) => {
      const sortParams = getNewSort(sortId, nextArrange);
      navigate({
        pathname: location.pathname,
        search: `?${updateLocationSearchParams(location, sortParams)}`,
      });
    },
    [location, sort, sort_by],
  );

  const onClose = useCallback(() => {
    navigate({
      pathname: location.pathname,
      search: omitFromQueryString(location.search, 'id'),
    });
  }, [location]);

  const handleCloseCreateTaskDialog = useCallback(() => {
    setOpenCreateTaskDialog(false);
  }, []);

  useKeyDow({
    targetKey: 'Escape',
    disabled: !taskId,
    callback: onClose,
  });

  const isEmptyStateTable = useMemo<boolean>(() => {
    const queries = fromQueryString(location.search);
    cleanDeep(queries);
    const s = toQueryString(queries);

    return (
      tabValue === ITabValue.Ongoing &&
      data?.items?.length === 0 &&
      !omitFromQueryString(s, 'tab')
    );
  }, [location, tabValue, data]);

  const headerTitle = useMemo(
    () => (
      <Tutorial
        isOpen={getTooltipOpen(TutorialData.TaskList.id)}
        onClose={() =>
          updateUserTooltip({
            [TutorialData.TaskList.id]: true,
          })
        }
        sx={{ maxWidth: 100, display: { xs: 'none', md: 'block' } }}
        {...TutorialData.TaskList}
      >
        <Typography variant='h2' data-testid='task-list-title'>
          Task List
        </Typography>
      </Tutorial>
    ),
    [],
  );

  const headerButtons = useMemo(() => {
    const isOpenTutorial = isFirstLoading
      ? false
      : getTooltipOpen(TutorialData.CreateTask.id);

    return (
      <StyledHeaderButtonContainer>
        <Button
          noRounder
          buttonType='text'
          onClick={() => {
            exportAllTaskMutation({
              statuses: statuses ? [statuses] : tabValue,
              ...(executor_ids && { executor_ids: [executor_ids] }),
              ...(search && { search }),
            });
          }}
          loading={isLoadingExportAllTask}
          sx={{
            [themes.breakpoints.down('md')]: {
              minWidth: 'unset !important',
            },
          }}
        >
          <Typography
            variant='body1'
            sx={{
              cursor: 'pointer',
              color: themes.color.violet500,
              fontWeight: 500,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 0.4,
            }}
          >
            <img src={ExportIcon} alt='export-icon' /> Export List
          </Typography>
        </Button>
        <Button
          buttonType='text'
          noRounder
          onClick={() => {
            setOpenImportTaskDialog(true);
          }}
          sx={{
            [themes.breakpoints.down('md')]: {
              minWidth: 'unset !important',
            },
          }}
        >
          <Typography
            variant='body1'
            sx={{
              cursor: 'pointer',
              color: themes.color.violet500,
              fontWeight: 500,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 0.4,
            }}
          >
            <img src={ImportIcon} alt='import-icon' /> Import Task
          </Typography>
        </Button>

        <Tutorial
          isOpen={isOpenTutorial}
          onClose={() =>
            updateUserTooltip({
              [TutorialData.CreateTask.id]: true,
            })
          }
          {...TutorialData.CreateTask}
        >
          <Button
            noRounder
            buttonType='primary-dark'
            onClick={() => {
              if (
                isStarterPlan &&
                org?.monthly_created_task >= org?.limit_task
              ) {
                return setIsOpenUpSellDialog(true);
              }
              return setOpenCreateTaskDialog(true);
            }}
            sx={{
              [themes.breakpoints.down('md')]: {
                minWidth: 'unset !important',
              },
            }}
          >
            <Typography
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 0.4,
              }}
            >
              <img src={AddIcon} alt='add-icon' /> Add Task
            </Typography>
          </Button>
        </Tutorial>
      </StyledHeaderButtonContainer>
    );
  }, [
    isFirstLoading,
    isStarterPlan,
    isLoadingExportAllTask,
    statuses,
    tabValue,
    executor_ids,
    search,
  ]);

  const tabs = useMemo(
    () => (
      <Tabs
        tabs={[
          {
            value: ITabValue.Ongoing,
            label: 'Ongoing',
            count: tabCounts.ongoing || 0,
          },
          {
            value: ITabValue.Completed,
            label: 'Completed',
            count: tabCounts.completed || 0,
          },
        ]}
        countWithoutBackground
        value={tabValue}
        onChange={(val) =>
          navigate({
            pathname: location.pathname,
            search: omitFromQueryString(
              addToQueryString(location.search, { tab: val }),
              [
                'statuses',
                'page',
                'id',
                'reassigned_status',
                'date_gte',
                'timezone',
                'length',
              ],
            ),
          })
        }
      />
    ),
    [location, tabCounts, tabValue],
  );

  const renderTaskListBody = () => (
    <>
      <StyledFilterContainer>
        <FilterForm
          onSubmit={onSubmit}
          onClear={onClear}
          onClearAll={onClearAll}
        />
        <Pagination totalPages={total_pages} currentPage={parseInt(page, 10)} />
      </StyledFilterContainer>
      <TaskTable
        data={tasks}
        handleSort={handleSort}
        isEmptyState={isEmptyStateTable}
        isLoading={isShowTaskListLoading}
        isFullEmpty={isFullEmpty}
        renderEmpty={
          <SetupYourTask
            setOpenCreateTask={setOpenCreateTaskDialog}
            setOpenImportTask={setOpenImportTaskDialog}
          />
        }
      />
      {!!tasks.length && (
        <Stack sx={{ pt: 2, alignItems: 'flex-end' }}>
          <Pagination
            totalPages={total_pages}
            currentPage={parseInt(page, 10)}
          />
        </Stack>
      )}
    </>
  );

  const renderTaskDetail = useMemo(() => {
    if (!taskId) return '';
    return (
      <TaskDetail
        taskId={taskId}
        handleCloseTaskDetail={onClose}
        containerStyles={{
          position: 'unset',
          width: '100% !important',
          height: '100% !important',
          maxWidth: 'unset !important',
        }}
      />
    );
  }, [taskId]);

  return (
    <>
      <PageLayout
        headerTitle={headerTitle}
        isFistLoading={isFirstLoading}
        sxHeaderContainer={{
          [themes.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
          },
          alignItems: 'flex-start',
          pt: themes.spacing(2.4),
          px: themes.spacing(2.4),
        }}
        sxContentContainer={{
          pt: themes.spacing(2.4),
          px: themes.spacing(2.4),
        }}
        sxHeaderLeftContainer={{
          'h2': {
            color: themes.color.violet700,
            lineHeight: '35px',
          },
          '& .MuiTabs-root': {
            '& .MuiTabs-indicator': {
              backgroundColor: `${themes.color.violet700} !important`,
            },
          },
          '& .MuiTab-root': {
            'padding': 0,
            '& .MuiTabs-indicator': {
              backgroundColor: `${themes.color.violet700} !important`,
            },
          },
          '& .MuiTabs-flexContainer': {
            gap: 2,
          },
          '& .MuiButtonBase-root': {
            'textTransform': 'unset',
            '& span': {
              color: `rgba(51, 54, 61, 0.40)`,
              fontWeight: 600,
            },
          },
          '& .Mui-selected': {
            '& span': {
              color: `${themes.color.violet500}`,
              fontWeight: 700,
            },
          },
        }}
        renderHeaderButtons={headerButtons}
        renderTabs={tabs}
      >
        {renderTaskListBody()}
      </PageLayout>
      {openCreateTaskDialog && (
        <DialogAddTask open onClose={handleCloseCreateTaskDialog} />
      )}

      {openImportTaskDialog && (
        <ImportTask
          open={openImportTaskDialog}
          setOpen={setOpenImportTaskDialog}
        />
      )}
      <StyledPanelDetailContainer
        role='presentation'
        isOpen={!!taskId}
        fixedWidth={480}
      >
        {renderTaskDetail}
      </StyledPanelDetailContainer>
    </>
  );
};
