import {
  LiveTrackingQueryKeys,
  useCancelTask,
  useGetTaskDetail,
  useGetTaskLogs,
  useUpdateTask,
} from '~/pages/LiveTracking/apis';
import { CSSProperties, useMemo, useRef, useState } from 'react';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import {
  convertedLocation,
  mappingTaskStatusData,
} from '~/pages/LiveTracking/utils';
import {
  formatPhoneNumber,
  formatDeliveryDateTimeTask,
} from '~/utils/formatter';
import { MarkerType, Task, TaskStatus } from '~/pages/LiveTracking/types';
import themes from '~/themes';
import { Icon } from '~/components/common/Icon';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ElementSelect } from '~/components/common/ElementSelect/ElementSelect';
import { IntegrationType } from '~/models/stores';
import { ToolTipText } from '~/components/common/Tooltip/TooltipText';
import { DriverAvatar } from '~/components/shared/AssignTask/DriverAvatar';
import { UpgradePlanButton } from '~/components/shared/UpgradePlan';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { ContentCopy, Done } from '@mui/icons-material';
import { TextLink } from '~/components/common/TextLink';
import Button from '~/components/common/Button';
import Tag from '~/components/common/Tag';
import { Rating } from '~/components/common/Rating';
import DropOffIcon from '~/assets/images/map/drop-off-black.svg';
import ReturnIcon from '~/assets/images/map/return-black.svg';
import StoreIcon from '~/assets/images/map/store-orange.svg';
import Modal from '~/components/common/Modal';
import { queryClient } from '~/lib/react-query';
import { showAlert, alertParams } from '~/components/common/Alert';
import { useFirstLoadingDetection } from '~/hooks';
import ReactToPrint from 'react-to-print';
import { useAuth } from '~/providers/AuthProvider';
import {
  StyledLightPurpleBg,
  StyledTaskDetail,
  StyledTaskImageDetail,
  StyledTaskStepBody,
  StyledTaskStepTitle,
} from '../../style';
import { TaskImage } from './components/TaskImage';
import { DriverProgressBar } from './components/DriverProgressBar';
import { TaskLog } from './components/TaskLog';
import { AssignDriver } from './components/AssignDriver';
import { FirstLoading } from '../FirstLoading';
import { WaybillLabelSize } from './components/WaybillLabelSize';
import { TaskRoutes } from '../../libs/TaskRoutes';
import { DialogAddTask } from '../AddTask';

type TaskDetailProps = {
  multiTaskIds?: string[];
  isAssignMultiTask?: boolean;
  taskId: string;
  taskRoutes?: TaskRoutes;
  containerStyles?: CSSProperties;
  handleCloseTaskDetail: () => void;
  setMultiTaskIds?: (v?: string[]) => void;
};

export const TaskStatusLabel = ({ status }: { status: TaskStatus }) => (
  <span
    style={{
      background: mappingTaskStatusData[status]?.color,
      color: 'white',
      fontWeight: 400,
      padding: '3px 10px',
      borderRadius: 5,
      width: 'fit-content',
    }}
  >
    {mappingTaskStatusData[status]?.text}
  </span>
);

export const TaskDetail: React.FC<TaskDetailProps> = ({
  multiTaskIds = [],
  taskRoutes = null,
  taskId: taskIdParam,
  isAssignMultiTask = false,
  containerStyles = {},
  setMultiTaskIds = () => {},
  handleCloseTaskDetail,
}) => {
  const { isStarterPlan } = usePaymentInfo();
  const { account } = useAuth();
  const [copied, setCopied] = useState(false);
  const [taskPhotoDetail, setTaskPhotoDetail] = useState<{
    src: string;
    title: string;
  }>(null);
  const [assignTaskId, setAssignTaskId] = useState(null);
  const [openEditTaskDialog, setOpenEditTaskDialog] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const printRef = useRef(null);

  const { data: taskDetailResp, isFetching: isFetchingTaskDetail } =
    useGetTaskDetail({
      params: {
        id: taskIdParam,
      },
      refetchInterval: openEditTaskDialog ? false : 10 * 1000,
      onSuccess: (resp: Task) => {
        taskRoutes?.hideMarkers([MarkerType.Driver]);
        if (resp?.executor?.lat && resp?.executor?.lng) {
          taskRoutes?.addDriverMarker(resp.executor);
        }
      },
    });

  const { data: taskLogs } = useGetTaskLogs({
    params: { id: taskIdParam },
    refetchInterval: 10 * 1000,
  });

  const { mutate: updateTaskMutation } = useUpdateTask({
    onSuccess: (resp) => {
      showAlert(alertParams.successDark);
      queryClient.invalidateQueries([
        LiveTrackingQueryKeys.TaskDetail,
        resp.id,
      ]);
      queryClient.invalidateQueries([LiveTrackingQueryKeys.TaskLog]);
      queryClient.invalidateQueries([LiveTrackingQueryKeys.TasksList]);
      queryClient.invalidateQueries([LiveTrackingQueryKeys.DriverList]);
    },
  });

  const { mutate: cancelTaskMutation } = useCancelTask({
    onSuccess: () => {
      handleCloseTaskDetail();
      queryClient.invalidateQueries([LiveTrackingQueryKeys.TasksList]);
      queryClient.invalidateQueries([LiveTrackingQueryKeys.DriverList]);
      showAlert(alertParams.successDark);
    },
  });

  const taskDetail = taskDetailResp || {};
  const {
    id: taskId,
    status,
    name,
    integration_type,
    tracking_url,
    pickup,
    delivery,
    picked_up_details,
    delivered_details,
    returned_details,
    note,
    rating,
    feed_back,
    expected_delivery_before,
    expected_delivery_after,
    require_pickup_proofs,
  } = taskDetail;
  const executor = taskDetail?.executor || {};
  const deliveryURL = `${window.location.origin}/t/${tracking_url}`;
  const isReturn = useMemo(
    () => [TaskStatus.Returned, TaskStatus.Returning].includes(status),
    [status],
  );

  const isFirstLoadingTaskDetail = useFirstLoadingDetection([
    isFetchingTaskDetail,
  ]);

  const isShowAssignment = [
    TaskStatus.Unassigned,
    TaskStatus.PendingPickup,
    TaskStatus.Delivering,
    TaskStatus.Returning,
  ].includes(status);

  const renderTaskImage = (data: { photos?: string[]; signature?: string }) => {
    if (!data) return <div />;
    return (
      <Stack direction='row' spacing={4}>
        {data.photos && (
          <TaskImage
            title='Image Captured'
            id={data.photos?.[0] || ''}
            taskId={taskId}
            onPreviewTaskImage={(d) => setTaskPhotoDetail(d)}
          />
        )}
        {data.signature && (
          <TaskImage
            title='Signature Provided'
            id={data.signature || ''}
            taskId={taskId}
            onPreviewTaskImage={(d) => setTaskPhotoDetail(d)}
          />
        )}
      </Stack>
    );
  };

  const headerComponent = useMemo(() => {
    const isShowPrintBtn = integration_type === IntegrationType.Breadstack;
    const isShowTaskActions = ![
      TaskStatus.Cancelled,
      TaskStatus.Completed,
      TaskStatus.Returned,
    ].includes(status);

    const taskActionOptions = isShowTaskActions
      ? [
          {
            label: 'Edit Task',
            value: 'edit',
          },
          {
            label: 'Duplicate Task',
            value: 'duplicate',
          },
          {
            label: 'Cancel Task',
            value: 'canceled',
          },
        ]
      : [
          {
            label: 'Duplicate Task',
            value: 'duplicate',
          },
        ];

    return (
      <Stack
        direction='row'
        spacing={1}
        alignItems='center'
        sx={{ background: themes.bg.white, p: 2 }}
      >
        <TaskStatusLabel status={status} />
        <Typography variant='h4'>{name}</Typography>
        <Stack
          ml='auto !important'
          direction='row'
          spacing={0.5}
          maxHeight={themes.spacing(3)}
        >
          <ElementSelect
            paperProps={{
              sx: {
                'width': 125,
                'borderRadius': '10px',
                '.MuiButtonBase-root': {
                  padding: '6px',
                },
              },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            elementSelect={() => (
              <MoreHorizIcon sx={{ color: themes.color.violet900 }} />
            )}
            iconButtonProps={{
              sx: { height: 30, width: 30 },
            }}
            onChange={(o) => {
              if (o.value === 'edit') {
                setOpenEditTaskDialog(true);
              }
              if (o.value === 'duplicate') {
                setOpenEditTaskDialog(true);
                setIsDuplicate(true);
              }
              if (o.value === 'canceled') {
                showAlert({
                  ...alertParams.warning,
                  title: 'Cancel Task',
                  cancelText: 'Cancel',
                  okText: 'Yes',
                  description:
                    'Once the task is cancelled, it cannot be reassigned.\n Are you sure to continue?',
                  onOk: () => {
                    cancelTaskMutation({ id: taskId });
                  },
                });
              }
            }}
            options={taskActionOptions}
          />

          {isShowPrintBtn && (
            <>
              <ReactToPrint
                trigger={() => (
                  <IconButton sx={{ width: 30 }}>
                    <Icon
                      name='printer'
                      size={18}
                      baseUrl='map'
                      color={themes.bg.darkPurple}
                    />
                  </IconButton>
                )}
                content={() => printRef.current.getRef()}
              />

              <div style={{ display: 'none' }}>
                {taskDetail?.id ? (
                  <WaybillLabelSize
                    ref={printRef}
                    task={taskDetail}
                    orgName={account?.org?.name}
                  />
                ) : (
                  ''
                )}
              </div>
            </>
          )}

          <IconButton onClick={() => handleCloseTaskDetail()}>
            <Icon name='close' size={14} color={themes.bg.darkPurple} />
          </IconButton>
        </Stack>
      </Stack>
    );
  }, [taskDetail, account]);

  const driverInfoComponent = useMemo(
    () => (
      <Stack
        sx={{
          borderRadius: '10px',
          background: themes.bg.white,
          p: 1.5,
        }}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Stack direction='row' spacing={1}>
            <DriverAvatar
              size={40}
              avatar={executor.avatar}
              status={executor.driver_status}
              name={executor.display_name}
              receiveAutoAssignTask={executor?.receive_auto_assign_task}
              idleAt={executor?.idle_at}
            />
            <Box>
              <Typography fontWeight={500}>
                <ToolTipText
                  text={executor?.display_name || 'No Driver'}
                  maxLength={25}
                />
              </Typography>
              <Typography>
                {formatPhoneNumber(executor.phone) || '-'}
              </Typography>
            </Box>
          </Stack>
          {isShowAssignment && executor?.id && (
            <ElementSelect
              paperProps={{
                sx: {
                  width: 260,
                  borderRadius: '10px',
                },
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              elementSelect={() => (
                <MoreHorizIcon sx={{ color: themes.color.violet900 }} />
              )}
              onChange={(o) => {
                if (o.value === 'Remove current driver from task') {
                  updateTaskMutation({
                    id: taskId,
                    remove_assignment: true,
                  });
                }
                if (o.value === 'Assign to another driver') {
                  setAssignTaskId(taskId);
                }
              }}
              options={[
                {
                  label: 'Assign to another driver',
                  value: 'Assign to another driver',
                },
                {
                  label: 'Remove current driver from task',
                  value: 'Remove current driver from task',
                },
              ]}
            />
          )}

          {isShowAssignment && !executor?.id && (
            <Button
              rounder10
              buttonType='primary-dark'
              onClick={() => setAssignTaskId(taskId)}
            >
              Assign
            </Button>
          )}
        </Stack>
        {taskId && <DriverProgressBar task={taskDetail} />}
        <Divider sx={{ my: 2 }} />

        {status === TaskStatus.Completed && (
          <>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography fontWeight={500}>Rating & Feedback</Typography>
              <UpgradePlanButton
                isShowButton={isStarterPlan}
                content={<Rating value={rating} readOnly />}
              />
            </Stack>
            {!isStarterPlan && feed_back && (
              <StyledLightPurpleBg sx={{ mt: 1 }}>
                {feed_back}
              </StyledLightPurpleBg>
            )}
          </>
        )}
        {status !== TaskStatus.Completed && (
          <>
            <Typography fontWeight={500} mb={1}>
              Delivery Tracking Link
            </Typography>
            {tracking_url && (
              <UpgradePlanButton
                isShowButton={isStarterPlan}
                content={
                  <Stack
                    display='flex'
                    direction='row'
                    gap={1}
                    alignItems='center'
                  >
                    <TextLink
                      style={{ color: themes.color.black }}
                      target='_blank'
                      to={deliveryURL}
                    >
                      {deliveryURL}
                    </TextLink>
                    {copied ? (
                      <Done sx={{ fontSize: 18, color: themes.color.green }} />
                    ) : (
                      <ContentCopy
                        sx={{ fontSize: 18, cursor: 'pointer' }}
                        onClick={() => {
                          navigator.clipboard.writeText(deliveryURL);
                          setCopied(true);
                          setTimeout(() => setCopied(false), 1000);
                        }}
                      />
                    )}
                  </Stack>
                }
              />
            )}
          </>
        )}
      </Stack>
    ),
    [taskDetail, copied, isStarterPlan],
  );
  const taskInfoComponent = useMemo(
    () => (
      <Stack
        sx={{
          borderRadius: '10px',
          background: themes.bg.white,
          p: 1.5,
        }}
      >
        {pickup && (
          <>
            <StyledTaskStepTitle>
              <img alt='store-icon' src={StoreIcon} width={12} height={12} />
              <Typography
                ml={1.2}
                fontWeight={500}
                data-testid='pickup-task-detail'
              >
                <ToolTipText text={convertedLocation(pickup)} maxLength={80} />
              </Typography>
            </StyledTaskStepTitle>
            <StyledTaskStepBody spacing={1}>
              <Typography
                data-testid='sender-task-detail'
                data-value={pickup?.name}
                sx={{ pt: 1 }}
              >
                Sender
                <ToolTipText text={`: ${pickup?.name || '-'}`} maxLength={70} />
              </Typography>
              <Typography
                data-testid='phone-pickup-task-detail'
                data-value={pickup?.phone}
              >
                Phone:&nbsp;
                <a href={`tel:${pickup?.phone || '-'}`}>
                  {pickup?.phone ? formatPhoneNumber(pickup?.phone) : ''}
                </a>
              </Typography>
              {picked_up_details?.barcode_scanned && require_pickup_proofs && (
                <Box width='fit-content'>
                  <Tag tag='waybillScanned' size='small' />
                </Box>
              )}
              {renderTaskImage(picked_up_details)}
            </StyledTaskStepBody>
          </>
        )}

        <StyledTaskStepTitle>
          <img alt='drop-off-icon' src={DropOffIcon} width={12} height={12} />

          <ToolTipText
            text={convertedLocation(delivery)}
            maxLength={80}
            textNode={(v) => (
              <Typography
                ml={1.2}
                fontWeight={500}
                data-testid='dropoff-task-detail'
              >
                {v}
              </Typography>
            )}
          />
        </StyledTaskStepTitle>
        <StyledTaskStepBody spacing={1} hiddenDashed={!isReturn}>
          <Typography
            variant='h5'
            sx={{
              background: themes.bg.midPurple,
              width: 'fit-content',
              p: themes.spacing(0.3, 0.5),
              borderRadius: '5px',
              mt: '10px !important',
            }}
          >
            Scheduled:{' '}
            {formatDeliveryDateTimeTask({
              afterDate: expected_delivery_after,
              beforeDate: expected_delivery_before,
            })}
          </Typography>
          <Typography
            data-testid='recipient-task-detail'
            data-value={delivery?.name}
          >
            Recipient
            <ToolTipText text={`: ${delivery?.name || '-'}`} maxLength={70} />
          </Typography>
          <Typography
            data-testid='phone-delivery-task-detail'
            data-value={delivery?.phone}
          >
            Phone:&nbsp;
            <a href={`tel:${delivery?.phone || '-'}`}>
              {delivery?.phone ? formatPhoneNumber(delivery?.phone) : ''}
            </a>
          </Typography>
          {delivery?.email && (
            <ToolTipText text={`Email: ${delivery.email}`} maxLength={40} />
          )}
          {note && <StyledLightPurpleBg>Note: {note}</StyledLightPurpleBg>}
          {isReturn && (
            <Box width='fit-content'>
              <Tag tag='failedToDeliver' size='small' />
            </Box>
          )}
          {renderTaskImage(delivered_details)}
        </StyledTaskStepBody>

        {isReturn && pickup && (
          <>
            <StyledTaskStepTitle sx={{ pl: 1 }}>
              <img alt='return-icon' src={ReturnIcon} width={12} height={12} />
              <ToolTipText
                text={convertedLocation(pickup)}
                maxLength={80}
                textNode={(v) => (
                  <Typography
                    ml={0.9}
                    fontWeight={500}
                    data-testid='pickup-task-detail'
                  >
                    {v}
                  </Typography>
                )}
              />
            </StyledTaskStepTitle>
            <StyledTaskStepBody spacing={1} hiddenDashed>
              <Typography>
                Sender
                {`: ${pickup?.name || '-'}`}
              </Typography>
              <Typography>
                Phone:&nbsp;
                <a href={`tel:${pickup?.phone || '-'}`}>
                  {pickup?.phone ? formatPhoneNumber(pickup?.phone) : ''}
                </a>
              </Typography>
              {renderTaskImage(returned_details)}
            </StyledTaskStepBody>
          </>
        )}
      </Stack>
    ),
    [taskDetail],
  );
  const timelineComponent = useMemo(
    () => (
      <Stack>
        <Typography mb={1} fontWeight={500}>
          Timeline
        </Typography>
        <TaskLog data={taskLogs} />
      </Stack>
    ),
    [taskLogs],
  );

  const taskPhotoDetailComponent = useMemo(
    () => (
      <Modal
        PaperProps={{
          sx: {
            background: '#2E2E2E',
            h6: {
              color: 'white',
              fontSize: 18,
            },
          },
        }}
        open={!!taskPhotoDetail}
        onClose={() => setTaskPhotoDetail(null)}
        title={taskPhotoDetail?.title}
        maxWidth='sm'
        fullWidth
      >
        <Stack
          sx={{
            content: {
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              height: 540,
              background: 'black',
            },
          }}
        >
          <StyledTaskImageDetail alt='task-img' src={taskPhotoDetail?.src} />
        </Stack>
      </Modal>
    ),
    [taskPhotoDetail],
  );

  if ((assignTaskId && assignTaskId === taskId) || isAssignMultiTask) {
    return (
      <StyledTaskDetail
        sx={{
          ...containerStyles,
        }}
      >
        <AssignDriver
          taskId={taskDetail.id}
          hubId={taskDetail.pickup_hub_id}
          pickup={taskDetail.skip_pickup ? null : taskDetail.pickup}
          delivery={delivery}
          isOpenTaskDetail={!!taskId}
          multiTaskIds={multiTaskIds}
          isAssignMultiTask={isAssignMultiTask}
          setMultiTaskIds={setMultiTaskIds}
          onBackToTaskDetail={() => setAssignTaskId(null)}
          handleCloseTaskDetail={handleCloseTaskDetail}
        />
      </StyledTaskDetail>
    );
  }

  return (
    <StyledTaskDetail
      sx={{
        ...containerStyles,
      }}
    >
      {headerComponent}
      {isFirstLoadingTaskDetail ? (
        <FirstLoading />
      ) : (
        <Stack
          spacing={1}
          className='customized-scrollbar'
          sx={{ overflow: 'auto', py: 1, px: 2 }}
        >
          {driverInfoComponent}
          {taskInfoComponent}
          {timelineComponent}
        </Stack>
      )}
      {!!taskPhotoDetail && taskPhotoDetailComponent}
      {openEditTaskDialog && (
        <DialogAddTask
          open={openEditTaskDialog}
          task={taskDetail}
          onClose={() => {
            setOpenEditTaskDialog(false);
            setIsDuplicate(false);
          }}
          isDuplicate={isDuplicate}
        />
      )}
    </StyledTaskDetail>
  );
};
