import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import Modal from '~/components/common/Modal';
import { IntegrationType } from '~/models/stores';
import { Stack } from '@mui/system';
import themes from '~/themes';
import { getPlatformIcon } from '~/utils/settings';
import { useCreateIntegration } from '~/services/api/stores';
import { LOCAL_STORAGE_KEYS, clearItem } from '~/utils/localStorage';
import { queryClient } from '~/lib/react-query';
import { queryKeys } from '~/services/api/queryKeys';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { BreadstackStore } from './components/BreadstackStore';
import { WooCommerceStore } from './components/WooCommerceStore';
import { AddStoreLayout } from './layout/AddStoreLayout';

type AddStoreProps = { onClose: () => void };

export const AddStore: React.FC<AddStoreProps> = ({ onClose }) => {
  const [platform, setPlatform] = useState<IntegrationType>(null);
  const [platformSelect, setPlatformSelect] = useState<IntegrationType>(
    IntegrationType.Woo,
  );
  const [step, setStep] = useState<number>(1);
  const isEnabledFlagShopifyIntegration = useFeatureIsOn('shopify-integration');

  const {
    mutate: createIntegrationMutation,
    isLoading: isLoadingCreateIntegration,
  } = useCreateIntegration({
    onSuccess: (resp) => {
      queryClient.invalidateQueries([queryKeys.stores.getListIntegration]);
      if (resp.integration_type === IntegrationType.Shopify) {
        clearItem(LOCAL_STORAGE_KEYS.SHOPIFY_REDIRECT_STORE_URL);
        window.location.href = resp.response_url;
      } else {
        onClose();
      }
    },
  });

  const platformOptions = [
    {
      label: 'Breadstack',
      value: IntegrationType.Breadstack,
    },
    {
      label: 'WooCommerce',
      value: IntegrationType.Woo,
    },
  ].filter((o) => {
    if (isEnabledFlagShopifyIntegration) {
      return o;
    }
    return o.value !== IntegrationType.Shopify;
  });

  const bodyComponent = useMemo(() => {
    switch (platform) {
      case IntegrationType.Breadstack:
        return (
          <BreadstackStore
            step={step}
            setStep={setStep}
            setPlatform={setPlatform}
            onClose={onClose}
            createIntegrationMutation={createIntegrationMutation}
            isLoadingCreateIntegration={isLoadingCreateIntegration}
          />
        );

      case IntegrationType.Woo:
        return (
          <WooCommerceStore
            step={step}
            setStep={setStep}
            setPlatform={setPlatform}
            onClose={onClose}
            createIntegrationMutation={createIntegrationMutation}
            isLoadingCreateIntegration={isLoadingCreateIntegration}
          />
        );
      default:
        return (
          <AddStoreLayout
            step={step}
            onClose={onClose}
            onNext={() => {
              setStep((s) => s + 1);
              setPlatform(platformSelect);
            }}
          >
            <RadioGroup
              sx={{ width: '100%' }}
              onChange={(e) => {
                setPlatformSelect(e.target.value as IntegrationType);
              }}
              value={platformSelect}
            >
              {platformOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  sx={{
                    '& > span:first-of-type': {
                      color: `${themes.color.violet900} !important`,
                      px: 0,
                      mr: 1,
                      py: 0,
                    },
                    'borderRadius': '10px',
                    'p': 1.6,
                    'mx': 0,
                    'width': '100%',
                    'boxSizing': 'border-box',
                    ...(option.value === platformSelect && {
                      background: '#F4F5F9',
                    }),
                  }}
                  value={option.value}
                  control={
                    <Radio
                      data-testid={`rdo-${option?.label
                        ?.toString()
                        ?.replace(' ', '-')
                        ?.toLowerCase()}`}
                    />
                  }
                  label={
                    <Stack direction='row' alignItems='center' spacing={1}>
                      <img
                        width={32}
                        height={32}
                        src={getPlatformIcon(option.value)}
                        alt='platform-icon'
                      />{' '}
                      <Typography fontWeight='bold' fontSize={16}>
                        {option.label}
                      </Typography>
                    </Stack>
                  }
                />
              ))}
            </RadioGroup>
          </AddStoreLayout>
        );
    }
  }, [platform, platformSelect, step, isLoadingCreateIntegration]);

  return (
    <Modal
      disableCloseOutside
      open
      onClose={onClose}
      PaperProps={{
        sx: {
          '& .MuiDialogContent-root': {
            p: 0,
          },
          'borderRadius': '8px !important',
          'maxWidth': '480px',
        },
      }}
    >
      {bodyComponent}
    </Modal>
  );
};
