/* eslint-disable import/no-extraneous-dependencies */
// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'js-cookie';
import { useContext, createContext, useMemo, useState, useEffect } from 'react';
import { firebaseGetToken, signInWCustomToken } from '~/services/firebase';
import { jwtDecode } from 'jwt-decode';
import {
  CUSTOM_TOKEN_COOKIES_KEY,
  RF_CUSTOM_TOKEN_COOKIES_KEY,
} from '~/constants/auth';
import {
  useCheckSessionSSO,
  useGenerateCustomToken,
  useLogout,
} from '~/services/api/auth';
import { queryClient } from '~/lib/react-query';
import { memoryStorage } from '~/utils/localStorage';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { BroadcastEvents, channel } from '~/lib/broadcast';
import { setAccountQueryData } from '~/utils/reactQuery';
import { useLocation } from 'react-router-dom';

export interface UnifiedAuthProps {
  isVerifyingSSO: boolean;
  setVerifyingSSO: (v: boolean) => void;
  isLoginLoading: boolean;
}
export const UnifiedAuthContext = createContext({} as UnifiedAuthProps);

const UnifiedAuthProvider = ({ children }) => {
  const location = useLocation();
  const isNewSSO = useFeatureIsOn(
    'acd-11071-cookie-attributes-not-set-optimal-security',
  );
  const [isVerifyingSSO, setVerifyingSSO] = useState(true);
  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const { mutate: logoutMutation } = useLogout({
    onSuccess: () => {
      window.location.reload();
    },
    onError: () => {
      setVerifyingSSO(false);
    },
  });
  const { data: tokenInfo, isFetching: isFetchingGenerateCustomToken } =
    useGenerateCustomToken({
      rfToken: Cookies.get(RF_CUSTOM_TOKEN_COOKIES_KEY),
      enabled: !isNewSSO,
    });

  const { data: tokenInfoSSO, isFetching: isFetchingCheckSession } =
    useCheckSessionSSO({
      enabled: isNewSSO && !isLoginLoading,
      onError: async () => {
        const idToken = await firebaseGetToken();
        if (idToken || memoryStorage.getIdToken()) {
          logoutMutation();
        }
      },
    });

  const isFirebaseUidEqual = (customTk: string, idTk: string): boolean => {
    if (!customTk || !idTk) return false;
    const customTkDecoded = jwtDecode<{ uid: string }>(customTk);
    const idTkDecoded = jwtDecode<{ user_id: string }>(idTk);
    return customTkDecoded?.uid === idTkDecoded?.user_id;
  };

  const verifyToken = async (customToken: string) => {
    const idToken = await firebaseGetToken();
    if (customToken) {
      try {
        if (
          !idToken ||
          (idToken && !isFirebaseUidEqual(customToken, idToken))
        ) {
          await signInWCustomToken(customToken);
          queryClient.invalidateQueries(['useGetUnifiedNavigation']);
        }
        const token = await firebaseGetToken();
        memoryStorage.setIdToken(token);
      } catch (err) {
        logoutMutation();
      } finally {
        setVerifyingSSO(false);
      }
    } else if (idToken) {
      logoutMutation();
    } else {
      setVerifyingSSO(false);
    }
  };

  const newVerifyToken = async (customToken: string) => {
    const idToken = await firebaseGetToken();
    if (customToken) {
      try {
        const isLogged = !!idToken;
        const isSameAccount = isFirebaseUidEqual(customToken, idToken);
        if (!isLogged || (isLogged && !isSameAccount)) {
          await signInWCustomToken(customToken);
          channel.postMessage({
            type: BroadcastEvents.RefreshOtherPageWhenLogged,
          });
          if (
            location.pathname.includes('/invite-user') &&
            isLogged &&
            !isSameAccount
          ) {
            window.location.href = '/';
          } else window.location.reload();
        }
        const token = await firebaseGetToken();
        memoryStorage.setIdToken(token);
      } catch (err) {
        logoutMutation();
      } finally {
        setVerifyingSSO(false);
      }
    } else if (idToken) {
      logoutMutation();
    } else {
      setVerifyingSSO(false);
    }
  };

  useEffect(() => {
    const customToken = tokenInfo?.custom_token;
    if (!isFetchingGenerateCustomToken && !isNewSSO) {
      verifyToken(customToken);
    }
  }, [tokenInfo, isFetchingGenerateCustomToken, isNewSSO]);

  useEffect(() => {
    const onfocus = async () => {
      if (!isVerifyingSSO && !isNewSSO) {
        const customToken = Cookies.get(CUSTOM_TOKEN_COOKIES_KEY);
        const idToken = await firebaseGetToken();
        const isEqualUid = isFirebaseUidEqual(customToken, idToken);
        if (
          (idToken && memoryStorage.getIdToken() && !customToken) ||
          (customToken && !isEqualUid) ||
          (idToken && customToken && !memoryStorage.getIdToken())
        ) {
          window.location.reload();
        }
      }
    };
    window.addEventListener('focus', onfocus);
    return () => {
      window.removeEventListener('focus', onfocus);
    };
  }, [isVerifyingSSO, isNewSSO]);

  // sync data multi tab
  useEffect(() => {
    const syncData = (d) => {
      const { type, payload } = d;
      if (type === BroadcastEvents.SyncLoginState) {
        setIsLoginLoading(payload);
      }
      if (type === BroadcastEvents.SyncAccountData) {
        const accountUpdated = payload.data;
        setAccountQueryData(accountUpdated, false);
      }
      if (type === BroadcastEvents.RefreshOtherPageWhenLogout) {
        window.location.reload();
      }
      if (type === BroadcastEvents.RefreshOtherPageWhenLogged) {
        if (location.pathname.includes('/invite-user')) {
          window.location.href = '/';
        } else window.location.reload();
      }
      if (type === BroadcastEvents.RefreshOtherPageWhenChangedOrg) {
        window.location.replace(window.location.pathname);
      }
      if (type === BroadcastEvents.RefreshOtherPageWhenActiveAccount) {
        window.location.reload();
      }
    };
    channel.addEventListener('message', syncData);
    return () => {
      channel.removeEventListener('message', syncData);
    };
  }, []);

  useEffect(() => {
    if (!isFetchingCheckSession && isNewSSO) {
      const customToken = tokenInfoSSO?.customToken;
      newVerifyToken(customToken);
    }
  }, [tokenInfoSSO, isFetchingCheckSession, isNewSSO]);

  const values = useMemo(
    () => ({
      isVerifyingSSO: isVerifyingSSO || isFetchingGenerateCustomToken,
      setVerifyingSSO,
      isLoginLoading,
    }),
    [
      isVerifyingSSO,
      isFetchingGenerateCustomToken,
      setVerifyingSSO,
      isLoginLoading,
    ],
  );

  return (
    <UnifiedAuthContext.Provider value={values}>
      {children}
    </UnifiedAuthContext.Provider>
  );
};

const useUnifiedAuthContext = () => {
  const context = useContext(UnifiedAuthContext);
  if (!context) {
    throw new Error(
      'Components cannot be rendered outside the Unified Auth Context',
    );
  }
  return context;
};

export { UnifiedAuthProvider, useUnifiedAuthContext };
