import { useLocation } from 'react-router-dom';
import { fromQueryString } from '~/utils/queryString';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import themes, { styled } from '~/themes';
import { ElementSelect } from '~/components/common/ElementSelect/ElementSelect';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Icon } from '~/components/common/Icon';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ToolTipText } from '~/components/common/Tooltip/TooltipText';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { alertParams, showAlert } from '~/components/common/Alert';
import { queryClient } from '~/lib/react-query';
import Button from '~/components/common/Button';
import moment from 'moment';
import { useFirstLoadingDetection } from '~/hooks';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DragStart,
} from 'react-beautiful-dnd';
import MenuIcon from '@mui/icons-material/Menu';
import { StyledTaskDetail } from '../../style';
import {
  LiveTrackingQueryKeys,
  useGetDriverDetail,
  useGetDriverRoutes,
  useOfflineDriverMutation,
  useOptimizeRoutes,
  useUpdateDriverRoutes,
} from '../../apis';
import { useLiveMapContext } from '../../hooks/useLiveMapContext';
import { DialogSendAlert } from '../DialogSendAlert';
import {
  convertDriverRoutesToRequestParams,
  getTaskIcon,
  groupDriverRoutes,
  isValidLatLngLiteral,
  sortDriverRoutes,
  updateDriverRoutes,
  updateOptimizedRoutesToDriverRoutes,
} from '../../utils';
import { OptimizeRouteLoading } from './OptimizeRouteLoading';
import {
  Driver,
  IDriverRoute,
  IDriverRouteResponse,
  MarkerType,
  TaskStatus,
} from '../../types';
import { FirstLoading } from '../FirstLoading';

type ManageRoutesProps = {};

export const StyledDivider = styled(Divider)(({ theme }) => ({
  'color': theme.color.gray900,
  'background': 'inherit !important',
  'fontSize': 14,
  'width': 'calc(100% + 40px)',
  '& .MuiDivider-wrapper': {
    maxWidth: '100%',
    overflow: 'hidden',
    flexGrow: '0.1',
    padding: '0 5px',
  },

  '&::before': {
    top: 0,
    borderTop: '1px dashed #797979',
    minWidth: '10%',
    flex: '1',
  },
  '&::after': {
    top: 0,
    borderTop: '1px dashed #797979',
    minWidth: '10%',
    flex: '1',
  },
}));

export const StyledDividerInner = styled('div')(() => ({
  overflow: 'hidden',
  display: 'inline-flex',
}));

enum BorderRouteType {
  Precede = 'precede',
  Succeed = 'succeed',
}

export const ManageRoutes = forwardRef(
  (props: ManageRoutesProps, ref: React.Ref<any>) => {
    const location = useLocation();
    const queryParams = fromQueryString(location.search);
    const {
      driverRoutes,
      driverId,
      mapBoundsPadding,
      setViewControls,
      handleCloseManageRoutes,
      handleMapFitBounds,
    } = useLiveMapContext();
    const isShowOfflineDriver = useFeatureIsOn('cf-show-offline-driver');

    const [openSendAlert, setOpenSendAlert] = useState(false);
    const [isOnManageRoutes, setIsOnManageRoutes] = useState(false);
    const [reReRenderCount, setReRenderCount] = useState(0);
    const [borderRoute, setBorderRoute] = useState<{
      type: BorderRouteType;
      idx: number;
    } | null>(null);
    const refetchGetDriverRoutesCountsRef = useRef(1);

    const routes = isOnManageRoutes
      ? driverRoutes.updateRoutes || []
      : driverRoutes?.routes || [];

    const enabledDropOffViewController = useCallback(() => {
      setViewControls((v: MarkerType[]) => [
        ...Array.from(new Set(v.concat([MarkerType.DropOff]))),
      ]);
    }, []);

    const {
      data: driverRoutesResp,
      refetch: refetchGetDriverRoutes,
      isFetching: isFetchingDriverRoutes,
    } = useGetDriverRoutes({
      params: {
        id: queryParams?.driver_id,
      },
      onSuccess: (driverRoutesResponse: IDriverRouteResponse[]) => {
        const routesGrouped: IDriverRoute[] =
          groupDriverRoutes(driverRoutesResponse);
        const routesSorted = sortDriverRoutes(routesGrouped);
        const routesUpdated = updateDriverRoutes(routesSorted);

        driverRoutes?.setDriverRoutes(routesUpdated);
        driverRoutes?.setUpdateDriverRoutes(routesUpdated);

        driverRoutes?.clearDriverRoutesMarkers();
        driverRoutes?.clearRoutes();

        if (routesUpdated.length) {
          driverRoutes?.addDriverRoutesMarkers(routesUpdated, {
            isOnManageRoutes: false,
            isOnlyOneRoute: driverRoutesResponse.length === 1,
          });
          driverRoutes?.setFnForceRender(setReRenderCount);
          driverRoutes?.drawRoutes({ isOnManageRoutes: false });
        }

        const driver = driverRoutes?.getDriverSelected();
        const driverRoutesMap = driverRoutes?.getDriverRoutesMap();

        if (isValidLatLngLiteral(driver)) {
          const driverPosition = { lat: driver.lat, lng: driver.lng };
          handleMapFitBounds(
            driverRoutesMap.map((r) => r.position).concat([driverPosition]),
            {
              padding: mapBoundsPadding.DRIVER_ROUTES,
              wait: 250,
            },
          );
        }

        setReRenderCount((v) => v + 1);
      },
    });

    const { mutate: offlineDriverMutation } = useOfflineDriverMutation({
      onSuccess: () => {
        handleCloseManageRoutes();
        queryClient.invalidateQueries([LiveTrackingQueryKeys.DriverList]);
      },
    });

    const {
      mutate: optimizedRoutesMutation,
      isLoading: isLoadingOptimizeRoute,
    } = useOptimizeRoutes({
      onSuccess: (optimizedRoutes: IDriverRouteResponse[]) => {
        setIsOnManageRoutes(true);
        const routesUpdatedOptimizedRoutes =
          updateOptimizedRoutesToDriverRoutes(optimizedRoutes, routes);
        const routesUpdated = updateDriverRoutes(routesUpdatedOptimizedRoutes);
        const routesSorted = sortDriverRoutes(routesUpdated, {
          isOnManageRoutes: true,
        });
        driverRoutes.setUpdateDriverRoutes(routesSorted);

        driverRoutes?.clearDriverRoutesMarkers();
        driverRoutes?.clearRoutes();

        driverRoutes?.addDriverRoutesMarkers(routesSorted, {
          isOnManageRoutes: true,
        });
        driverRoutes.drawRoutes({ isOnManageRoutes: true });
        setReRenderCount((v) => v + 1);
      },
    });

    const {
      mutate: updateDriverRoutesMutation,
      isLoading: isLoadingUpdateRoutes,
    } = useUpdateDriverRoutes({
      onSuccess: () => {
        const routesUpdated = driverRoutes.updateRoutes.map((r) => ({
          ...r,
          sort_id: r.update_sort_id || null,
          update_sort_id: null,
        }));

        driverRoutes.setUpdateDriverRoutes(routesUpdated);
        driverRoutes.setDriverRoutes(routesUpdated);

        driverRoutes.clearDriverRoutesMarkers();
        driverRoutes.clearRoutes();

        driverRoutes?.addDriverRoutesMarkers(routesUpdated, {
          isOnManageRoutes: false,
        });
        driverRoutes.drawRoutes({ isOnManageRoutes: false });

        enabledDropOffViewController();
        setIsOnManageRoutes(false);
        setReRenderCount((v) => v + 1);

        queryClient.invalidateQueries([LiveTrackingQueryKeys.DriverRoutes]);
        showAlert(alertParams.successDark);
      },
    });

    useGetDriverDetail({
      params: {
        id: driverId,
      },
      onSuccess: (driver: Driver) => {
        if (driver?.id && driverRoutes) {
          driverRoutes.clearDriverInfoWindow();
          driverRoutes.addDriverInfoWindow(driver);
        }
      },
    });

    useImperativeHandle(
      ref,
      () => ({
        forceRerender: () => setReRenderCount((v) => v + 1),
      }),
      [],
    );

    const isFirstLoadingManageRoutes = useFirstLoadingDetection([
      isFetchingDriverRoutes,
    ]);
    const driverData = driverRoutes?.getDriverSelected() || {};

    const isOnlyOneTaskManageRoutes = useMemo(
      () => driverRoutesResp.length === 1,
      [driverRoutesResp],
    );

    useEffect(() => {
      if (driverId) {
        driverRoutes?.clearDriverInfoWindow();
        driverRoutes?.clearDriverRoutesMarkers();
        driverRoutes?.clearRoutes();
        enabledDropOffViewController();
        setIsOnManageRoutes(false);
      }
    }, [driverId, driverRoutes]);

    useEffect(() => {
      const isPendingDriverRoutes = driverRoutesResp?.some(
        (d) => !d.delivery_index && !d.pickup_index && !d.return_index,
      );

      if (
        isPendingDriverRoutes &&
        refetchGetDriverRoutesCountsRef.current >= 3
      ) {
        refetchGetDriverRoutesCountsRef.current += 1;
        refetchGetDriverRoutes();
      } else {
        refetchGetDriverRoutesCountsRef.current = 0;
      }
    }, [driverRoutesResp, refetchGetDriverRoutesCountsRef.current]);

    const handleSetRoute = useCallback(() => {
      const routesParams = convertDriverRoutesToRequestParams(routes);
      updateDriverRoutesMutation({
        driver_id: driverData.id,
        routes: routesParams,
      });
    }, [routes, driverData]);

    const handleReverseRoute = useCallback(() => {
      setIsOnManageRoutes(false);
      driverRoutes.clearDriverRoutesMarkers();
      driverRoutes.clearRoutes();

      driverRoutes.setUpdateDriverRoutes(driverRoutes.routes);
      driverRoutes?.addDriverRoutesMarkers(driverRoutes.routes, {
        isOnManageRoutes: false,
      });

      driverRoutes?.drawRoutes({ isOnManageRoutes: false });
      setReRenderCount((v) => v + 1);
    }, []);

    const handleDragStart = useCallback(
      (start: DragStart) => {
        const draggingRoute = routes[start.source.index];
        if (draggingRoute.task_status !== TaskStatus.PendingPickup)
          return false;

        if (draggingRoute.marker_type === MarkerType.Store) {
          const draggingRouteDropOffIdx = routes.findIndex(
            (r) =>
              r.task_id === draggingRoute.task_id &&
              r.marker_type === MarkerType.DropOff,
          );

          return setBorderRoute({
            type: BorderRouteType.Precede,
            idx: draggingRouteDropOffIdx,
          });
        }

        if (draggingRoute.marker_type === MarkerType.DropOff) {
          const draggingRouteStoreIdx = routes.findIndex(
            (r) =>
              r.task_id === draggingRoute.task_id &&
              r.marker_type === MarkerType.Store,
          );

          return setBorderRoute({
            type: BorderRouteType.Succeed,
            idx: draggingRouteStoreIdx,
          });
        }
        return true;
      },
      [routes],
    );

    const reorder = (
      routesUpdate: IDriverRoute[],
      startIndex: number,
      endIndex: number,
    ): IDriverRoute[] => {
      const result = Array.from(routesUpdate);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    const handleDragEnd = useCallback(
      (result: DropResult) => {
        const start = result.source.index;
        const end = result.destination.index;

        const isInvalidEndIndex =
          borderRoute &&
          ((borderRoute.type === BorderRouteType.Precede &&
            borderRoute.idx <= end) ||
            (borderRoute.type === BorderRouteType.Succeed &&
              borderRoute.idx >= end));

        // dropped outside the list || invalid end index || dropped on the same index
        if (!result.destination || isInvalidEndIndex || start === end) {
          return setBorderRoute(null);
        }

        const routesReordered = reorder(driverRoutes.updateRoutes, start, end);
        const routesUpdated = routesReordered.map((r, idx) => ({
          ...r,
          update_sort_id: idx + 1,
        }));

        driverRoutes.setUpdateDriverRoutes(
          routesUpdated.map((r, idx) => ({ ...r, update_sort_id: idx + 1 })),
        );

        driverRoutes.clearDriverRoutesMarkers();
        driverRoutes.clearRoutes();

        driverRoutes.addDriverRoutesMarkers(routesUpdated, {
          isOnManageRoutes: true,
        });
        driverRoutes.drawRoutes({ isOnManageRoutes: true });

        setIsOnManageRoutes(true);
        setBorderRoute(null);
        enabledDropOffViewController();
        return setReRenderCount((v) => v + 1);
      },
      [routes, borderRoute],
    );

    const getRouteIdx = useCallback(
      (route: IDriverRoute, routeArrIdx: number) => {
        if (isOnlyOneTaskManageRoutes) return '';
        if (isOnManageRoutes) return route.update_sort_id || '';
        // return route.sort_id || '';
        return routeArrIdx + 1;
      },
      [isOnlyOneTaskManageRoutes, isOnManageRoutes],
    );

    const headerComponent = useMemo(() => {
      const { display_name } = driverData;

      return (
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          sx={{ background: themes.bg.white, p: 2 }}
        >
          <ToolTipText
            text={display_name || '-'}
            maxLength={25}
            textNode={(v) => <Typography variant='h4'>{v}</Typography>}
          />
          <Stack
            ml='auto !important'
            direction='row'
            spacing={0.5}
            maxHeight={themes.spacing(3)}
          >
            <ElementSelect
              paperProps={{
                sx: {
                  width: 120,
                  borderRadius: '10px',
                },
              }}
              iconButtonProps={{
                sx: {
                  width: '30px !important',
                },
              }}
              elementSelect={() => (
                <MoreHorizIcon sx={{ color: themes.color.violet900 }} />
              )}
              onChange={(o) => {
                if (o.value === 'send-alert') {
                  setOpenSendAlert(true);
                }
                if (o.value === 'offline-driver') {
                  showAlert({
                    ...alertParams.warning,
                    description:
                      'You are about to offline this driver.\n Are you sure you want to continue?',
                    cancelText: 'Cancel',
                    onOk: () => {
                      offlineDriverMutation({ id: driverData.id });
                    },
                  });
                }
              }}
              options={[
                {
                  label: 'Send Alert',
                  value: 'send-alert',
                },
                {
                  label: 'Offline Driver',
                  value: 'offline-driver',
                },
              ].filter((o) =>
                !isShowOfflineDriver ? o.value !== 'offline-driver' : o,
              )}
            />
            <IconButton onClick={() => handleCloseManageRoutes()}>
              <Icon name='close' size={14} color={themes.bg.darkPurple} />
            </IconButton>
          </Stack>
        </Stack>
      );
    }, [driverData, isOnManageRoutes]);

    const listRoutesComponent = useMemo(
      () => (
        <DragDropContext
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <Droppable
            droppableId='droppable'
            renderClone={(cloneProvided, snapshot, rubric) => {
              const route = routes[rubric.source.index];
              return (
                <Stack
                  width='100%'
                  key={route.id}
                  direction='row'
                  alignItems='center'
                  ref={cloneProvided.innerRef}
                  {...cloneProvided.draggableProps}
                  {...cloneProvided.dragHandleProps}
                >
                  <MenuIcon />
                  <Stack
                    width='100%'
                    alignItems='center'
                    justifyContent='center'
                    ml={0.5}
                    sx={{
                      background: themes.bg.midPurple,
                      opacity: 0.9,
                      borderRadius: '5px',
                      position: 'relative',
                      height: 62,
                    }}
                  >
                    <Typography>
                      drag and drop{' '}
                      <img
                        style={{ marginRight: 5 }}
                        src={getTaskIcon(route.marker_type)}
                        alt='task-icon'
                      />
                      {route.task_name || ''}
                    </Typography>
                  </Stack>
                </Stack>
              );
            }}
          >
            {(droppableProvided) => (
              <Stack
                height='100%'
                p={2}
                spacing={1}
                className='customized-scrollbar'
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
                sx={{
                  overflow: 'auto',
                  background: themes.bg.lightPurple,
                  boxSizing: 'border-box',
                  overflowAnchor: 'none',
                }}
              >
                {!isOnlyOneTaskManageRoutes && driverRoutesResp.length ? (
                  <Stack
                    width='100%'
                    sx={{
                      background: themes.bg.midPurple,
                      borderRadius: '5px',
                      marginBottom: '10px !important',
                    }}
                    textAlign='center'
                  >
                    <Typography p={0.2}>
                      Drag and drop to route tasks or{' '}
                      <span
                        role='button'
                        tabIndex={0}
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        onClick={() =>
                          optimizedRoutesMutation({ driver_id: driverData.id })
                        }
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            optimizedRoutesMutation({
                              driver_id: driverData.id,
                            });
                          }
                        }}
                      >
                        Optimize Route
                      </span>
                    </Typography>
                  </Stack>
                ) : (
                  ''
                )}

                {routes.map((route, index) => {
                  const isShowDivider = index === borderRoute?.idx;
                  const isDisabledDropItemAnimation =
                    (borderRoute?.type === BorderRouteType.Precede &&
                      index > borderRoute.idx - 1) ||
                    (borderRoute?.type === BorderRouteType.Succeed &&
                      index < borderRoute.idx + 1);
                  return (
                    <Draggable
                      key={route.id}
                      draggableId={route.id}
                      index={index}
                      isDragDisabled={isOnlyOneTaskManageRoutes}
                    >
                      {(draggableProvided) => (
                        <Stack
                          width='100%'
                          key={route.id}
                          direction='column'
                          alignItems='center'
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          style={{
                            ...draggableProvided.draggableProps.style,
                            ...(isDisabledDropItemAnimation && {
                              transition: 'none !important',
                              transform: 'none !important',
                            }),
                          }}
                        >
                          {isShowDivider &&
                            borderRoute?.type === BorderRouteType.Precede && (
                              <StyledDivider
                                sx={{
                                  marginTop: '10px',
                                  marginBottom: '20px',
                                }}
                                key={`divider-${route.id}`}
                              >
                                <StyledDividerInner>
                                  it must precede its linked drop-off
                                </StyledDividerInner>
                                {isDisabledDropItemAnimation ? 'true' : 'false'}
                              </StyledDivider>
                            )}

                          <Stack
                            width='100%'
                            direction='row'
                            alignItems='center'
                          >
                            <Typography
                              textAlign='center'
                              minWidth={30}
                              fontWeight={500}
                            >
                              {getRouteIdx(route, index)}
                            </Typography>

                            <Stack
                              width='100%'
                              p={1}
                              ml={0.5}
                              sx={{
                                background: themes.bg.white,
                                borderRadius: '5px',
                                position: 'relative',
                                ...(isShowDivider && {
                                  background: themes.bg.midPurple,
                                }),
                              }}
                            >
                              <Stack
                                spacing={0.5}
                                direction='row'
                                justifyContent='space-between'
                              >
                                <Typography>
                                  <img
                                    style={{ marginRight: 5 }}
                                    src={getTaskIcon(route.marker_type)}
                                    alt='task-icon'
                                  />
                                  {route.task_name || ''}
                                </Typography>
                                <Typography
                                  height={21}
                                  color={
                                    route.is_alerted ? '#F64066' : '#979DC3'
                                  }
                                >
                                  {route.expected_delivery_before || ''}
                                </Typography>
                              </Stack>
                              <Stack
                                spacing={0.5}
                                direction='row'
                                justifyContent='space-between'
                                flex={1}
                              >
                                <ToolTipText
                                  text={`${route.address_1}, ${route.city}`}
                                  maxLength={route.eta_time ? 18 : 40}
                                  textNode={(v) => <Typography>{v}</Typography>}
                                />
                                <Typography
                                  color={
                                    route.is_alerted ? '#F64066' : '#979DC3'
                                  }
                                >
                                  {route.eta_time
                                    ? `ETA ${moment()
                                        .add(route.eta_time, 's')
                                        .calendar(null, {
                                          sameDay: 'h:mm a',
                                          nextDay: 'h:mm a | [Tmrw]',
                                          lastDay: 'h:mm a | [Ystd]',
                                          nextWeek: 'hh:mm a | MM/DD',
                                          lastWeek: 'hh:mm a | MM/DD',
                                          sameElse: 'hh:mm a | MM/DD',
                                        })}`
                                    : '-'}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                          {isShowDivider &&
                            borderRoute?.type === BorderRouteType.Succeed && (
                              <StyledDivider
                                key={`divider-${route.id}`}
                                sx={{
                                  marginBottom: '10px',
                                  marginTop: '20px',
                                }}
                              >
                                <StyledDividerInner>
                                  it must follow its linked pick up
                                </StyledDividerInner>
                              </StyledDivider>
                            )}
                        </Stack>
                      )}
                    </Draggable>
                  );
                })}
                {droppableProvided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      ),
      [
        reReRenderCount,
        isOnManageRoutes,
        borderRoute,
        driverData.id,
        isOnlyOneTaskManageRoutes,
      ],
    );

    const bottomButtonsComponent = useMemo(() => {
      if (!isOnManageRoutes) return '';
      return (
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          justifyContent='space-between'
          sx={{ background: themes.bg.white, p: 2 }}
        >
          <Button
            buttonType='default'
            fullWidth
            rounder10
            onClick={() => {
              showAlert({
                ...alertParams.warnDark,
                onOk: handleReverseRoute,
              });
            }}
          >
            Cancel
          </Button>
          <Button
            buttonType='primary-dark'
            fullWidth
            rounder10
            disabled={routes.filter((r) => !!r.update_sort_id).length === 0}
            loading={isLoadingUpdateRoutes}
            onClick={handleSetRoute}
          >
            Save
          </Button>
        </Stack>
      );
    }, [routes, isOnManageRoutes, isLoadingUpdateRoutes]);

    return (
      <StyledTaskDetail>
        {headerComponent}
        {isFirstLoadingManageRoutes ? (
          <FirstLoading />
        ) : (
          <>
            {listRoutesComponent}
            {bottomButtonsComponent}
          </>
        )}
        {openSendAlert && (
          <DialogSendAlert
            open={openSendAlert}
            onClose={() => setOpenSendAlert(false)}
          />
        )}
        {isLoadingOptimizeRoute && <OptimizeRouteLoading />}
      </StyledTaskDetail>
    );
  },
);
